import React from "react";

export const Container = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={`max-w-xl px-4 mx-auto md:max-w-screen-xl lg:px-8 ${className}`}
    >
      {children}
    </div>
  );
};
